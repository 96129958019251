import { api } from ":mods";
import { ENDPOINTS } from "../const";



export async function getShowExhibition(id:string|number) {
  return api
    .getAuth(ENDPOINTS.show_exhibition_id_student + id + "/")
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function updateShowExhibition(id:string|number,data) {
      return api
        .putAuth(ENDPOINTS.show_exhibition_id_student + id + "/", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then(async (res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });

    }